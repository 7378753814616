import React, { useState } from 'react';
import './Site.css';  // Optional: you can create a separate CSS file for header styling
import logo from "../../assets/images/logo.png"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faArrowTrendUp, faBriefcase, faBriefcaseMedical, faChartColumn, faChartPie, faDisplay, faKey, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faSquareDribbble, faYoutube } from '@fortawesome/free-brands-svg-icons';
import arth from "./image/arth.png";
import au from "./image/au.png";
import bon from "./image/bon.png";
import cry from "./image/cry.png";
import dy from "./image/dy.png";
import kai from "./image/kai.png";
import ken from "./image/ken.png";
import kwlog from "./image/kwlog.png";
import m3 from "./image/m3.png";
import opu from "./image/opu.png";

import dr from "./image/3D.png";
import IMG_4273 from "./image/IMG_4273-100x100.jpg";
import IMG_4274 from "./image/IMG_4274-100x100.jpg";
import ISO_27001_Final from "./image/ISO_27001_Final-Logo-150x150.jpg";
import ISO_27001_Final_JPG from "./image/ISO_27001_Final-Logo.jpg";
import Meta_Logo from "./image/Meta-Logo-1024x576.png";
import Meta1000x750150 from "./image/Meta1000x750-150x150.png";
import Meta1000x750 from "./image/Meta1000x750.png";
import PNG_GDPRe1 from "./image/PNG_GDPR-e1672263252689-150x150.png";
import PNG_GDPR from "./image/PNG_GDPR-e1672263252689.png";
import adminajax from "./image/admin-ajax.png";
import arth100 from "./image/arth-100x100.png";
import headerbtm from "./image/header-btm-shape.png";
import mobile_mockup1 from "./image/mobile_mockup-1.png";
import mobile_mockup_2 from "./image/mobile_mockup_2.png";
import mockup from "./image/mockup.png";
import shape_03 from "./image/shape_03.png";
import top_image from "./image/top_image.png";

function Header() {
  const [open, setOpen] = useState('1');
  const [activeKey, setActiveKey] = useState(null);
  const toggle = (id) => {
    if (open === id) {
      setOpen();
  
    } else {
      setOpen(id);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

    // Toggle function to open/close the accordion
    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const [activeCollapse, setActiveCollapse] = useState(null);

    const toggleCollapse = (id) => {
        setActiveCollapse(prevState => (prevState === id ? null : id));
    };


  const logos = [
    { src: au, delay: "0.0s" },
    { src: arth, delay: "0.1s" },
    { src: kwlog, delay: "0.2s" },
    { src: bon, delay: "0.3s" },
    { src: m3, delay: "0.4s" },
    { src: cry, delay: "0.5s" },
    { src: kai, delay: "0.6s" },
    { src: ken, delay: "0.7s" },
    { src: dy, delay: "0.8s" },
    { src: opu, delay: "0.9s" },
  ];

  const handleLoginClick = () => {
    // Redirect to the external login URL
    window.location.href = 'https://wepos.ai/login';
  };

  const statsData = [
    { value: "44", label: "Opt in Businesses", color: "#40a4f5" },
    { value: "98%", label: "Open rate", color: "#f5a623" },
    { value: "65%", label: "Click rate", color: "#3bde9b" },
    { value: "50K", label: "Users", color: "#f55fa4" },
  ];

  const faqItems = [
    {
      id: 'c7a5b6a',
      title: 'Break Engagement Barriers',
      icon: faLocationArrow,
      body: 'Replace traditional communication methods like email, SMS, and in-app messaging with WhatsApp to significantly boost your response rates.',
    },
    {
      id: 'd1fc340',
      title: 'A Better Way To Scale',
      icon: faBriefcaseMedical,
      body: 'Manage multiple users under a single WhatsApp number to streamline your operations and improve ROI.',
    },
    {
      id: '6f3d45a',
      title: 'Multiple Channels, One Unified Approach',
      icon: faArrowTrendUp,
      body: 'Integrate your marketing efforts across Facebook, Instagram, and WhatsApp.',
    },
    {
      id: '8716ede',
      title: 'Discover More With Social Media Synergy',
      icon: faLocationArrow,
      body: 'With WePOS, tap into the unparalleled share of social media messaging dominated by WhatsApp, Facebook, and Instagram.',
    },
  ];

  return (
    <>

      <div
          data-elementor-type="wp-post"
          data-elementor-id="3550"
          className="elementor elementor-3550"
          style={{position:"relative", background: "white"}}
      >
            <header className="header-site">
      <div className="container-site">
        <div className="logo">
          <img src={logo} alt="wepos" className="header-logo" />
        </div>
        <nav className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <a href="#about">About</a>
          <a href="#features">Features</a>
          <a href="#pricing">Pricing</a>
          <a href="#contact">Contact</a>
         <a href="#resources">Resources</a>
         <a href="#" className="login-mobile" onClick={handleLoginClick}>Login</a>
        </nav>
        <div className="login-button" onClick={handleLoginClick}>Login</div>
        <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
          ☰
        </button>
      </div>
    </header>
       <section
          className="elementor-section elementor-top-section elementor-element elementor-element-72f353cf elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="72f353cf"
          data-element_type="section"
          id="home"
        >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-19820c5c"
          data-id="19820c5c"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-3d9e0fad elementor-widget elementor-widget-saasland_appart_hero"
              data-id="3d9e0fad"
              data-element_type="widget"
              data-widget_type="saasland_appart_hero.default"
            >
              <div className="elementor-widget-container">
                <section className="n_hero_banner_area">
                  <svg
                    id="hero_shape2_normal"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >

                    <defs>
                      <linearGradient
                        id="PSgrad_0"
                        x1="0%"
                        x2="76.604%"
                        y1="64.279%"
                        y2="0%"
                      >
                        <stop offset="0%" stopColor="#e0d7fb" stopOpacity="1" />
                        <stop offset="100%" stopColor="#e0d7fb" stopOpacity="1" />
                      </linearGradient>
                    </defs>
                    <path
                      fillRule="evenodd"
                      fill="rgb(255, 255, 255)"
                      d="M0.000,246.000 C0.000,246.000 326.728,190.237 710.653,123.017 C937.017,83.384 1398.662,3.976 1398.662,3.976 C1398.662,3.976 1524.189,5.641 1668.565,26.591 C1813.299,47.594 1920.000,84.745 1920.000,84.745 L1920.000,323.000 L0.000,323.000 L0.000,246.000 Z"
                    />
                    <path
                      fill="url(#PSgrad_0)"
                      d="M0.000,323.249 C0.000,-57.945 0.000,623.445 0.000,242.251 C0.000,242.251 141.533,218.272 347.776,183.613 C479.132,161.538 636.827,133.656 800.746,105.827 C943.681,81.561 1097.680,52.804 1239.269,28.559 C1291.889,19.548 1358.059,5.849 1393.180,1.251 C1434.086,-4.103 1581.001,11.184 1661.097,24.221 C1818.678,49.869 1920.000,84.251 1920.000,84.251 L1920.000,190.407 C1813.062,96.085 1433.376,28.053 1285.064,28.053 L0.000,323.249 Z"
                    />
                  </svg>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 col-md-12">
                        <div className="n_banner_content">
                          <h1 className="wow fadeInUp title" data-wow-delay="300ms" style={{color:"white"}}>
                            Welcome WePOS
                          </h1>
                          <p className="wow fadeInUp" data-wow-delay="500ms" style={{color:"white"}}>
                            Grow Your Business on WhatsApp
                            <br />
                            Personalise communication and sell more with the
                            WhatsApp Business API platform that automates
                            marketing, sales, service, and support.
                          </p>
                          <a
                            className="agency_banner_btn btn_custom elementor-repeater-item-08a894d"
                            href="https://youtu.be/i9WpgINslV0"
                          >
                            See How We Do it
                          </a>
                          <a
                            className="agency_banner_btn btn_custom elementor-repeater-item-dfd08fc"
                            href="#contact-us"
                          >
                            Contact Sales
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-4 offset-lg-1 col-md-12">
                        <div className="mobile_img wow fadeInUp animated" data-wow-delay="0.2s">
                          <img
                            decoding="async"
                            src={mockup}
                            alt="f_img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shape_banners">
                    <img
                      decoding="async"
                      className="img3 wow fadeIn"
                      data-wow-delay="1.5s"
                      src={dr}
                      alt="f_img"
                    />
                  </div>
                  <div className="shape_banners_left">
                    <img
                      decoding="async"
                      className="header-btm-shape wow fadeIn"
                      data-wow-delay="1.5s"
                      src={headerbtm}
                      alt="f_img"
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-6b035d94 elementor-section-full_width about-us elementor-section-height-default" id="about-us">
        <div className="elementor-container elementor-column-gap-no">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d1d525c">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-7422cc39 about elementor-widget elementor-widget-saasland_appart_c2a" id="about">
                <div className="elementor-widget-container">
                  <section className="about_area">
                    {/* <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                      <li><img decoding="async" className="br_shape" src="https://demo.wepos.ai/wp-content/plugins/saasland-core/widgets/images/appart-new/line_01.png" alt="WePOS" /></li>
                      <li><img decoding="async" className="br_shape" src="https://demo.wepos.ai/wp-content/plugins/saasland-core/widgets/images/appart-new/line_02.png" alt="WePOS" /></li>
                      <li><img decoding="async" className="br_shape" src="https://demo.wepos.ai/wp-content/plugins/saasland-core/widgets/images/appart-new/line_03.png" alt="WePOS" /></li>
                      <li><img decoding="async" className="br_shape" src="https://demo.wepos.ai/wp-content/plugins/saasland-core/widgets/images/appart-new/line_04.png" alt="WePOS" /></li>
                      <li><img decoding="async" src="https://demo.wepos.ai/wp-content/plugins/saasland-core/widgets/images/appart-new/line_05.png" alt="WePOS" /></li>
                      <li><img decoding="async" src="https://demo.wepos.ai/wp-content/plugins/saasland-core/widgets/images/appart-new/line_06.png" alt="WePOS" /></li>
                      <li><img decoding="async" src="https://demo.wepos.ai/wp-content/plugins/saasland-core/widgets/images/appart-new/shape_01.png" alt="WePOS" /></li>
                      <li><img decoding="async" src="https://demo.wepos.ai/wp-content/plugins/saasland-core/widgets/images/appart-new/shape_02.png" alt="WePOS" /></li>
                    </ul> */}
                    <img decoding="async" className="shape wow fadeInRight" src={shape_03} alt="WePOS" />
                    <div className="container">
                      <div className="row row_reverse align_items_center">
                        <div className="col-lg-7">
                          <div className="about_img text-right">
                            <img decoding="async" src={top_image} alt="WePOS" />
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="about_content">
                            <h2 className="h_color f_36 wow fadeInUp" style={{fontSize:"2rem"}}>WePOS</h2>
                            <div className="wow fadeInUp" style={{ animationDelay: "300ms" }}>
                              <p>Where innovation meets adaptability, transforming the landscape of hospitality and diverse industries alike. We understand that the need for efficient customer data management extends far beyond restaurants, encompassing cafes, stores, salons, wellness centres, and more. WePOS is not just a solution; it's a versatile platform that empowers businesses across sectors to enhance customer engagement, streamline operations, and drive success through data-driven strategies.</p>
                            </div>
                            <a href="#" className="n_banner_btn wow fadeInUp" style={{ animationDelay: "450ms" }}>
                              Discover More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="elementor-section elementor-top-section elementor-element elementor-element-70db7e1f elementor-section-boxed">
        <div className="elementor-container elementor-column-gap-no">
          <div className="stats-container">
            {statsData.map((stat, index) => (
              <div className="stat-item" key={index}>
                <h2 style={{ color: stat.color }}>{stat.value}</h2>
                <p>{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="faq" className="elementor-section elementor-top-section elementor-section-full_width">
      <div className="elementor-container">
        <div className="elementor-column elementor-col-100 elementor-top-column">
          <div className="elementor-widget-wrap">
            <div id="whatweoffer" className="elementor-widget">
              <div className="elementor-widget-container">
                <section className="faq_solution_area features_area_pad">
                  {/* <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    {['line_17.png', 'line_18.png', 'line_09.png', 'line_20.png', 'line_21.png', 'line_22.png', 'line_23.png', 'line_24.png', 'line_25.png', 'line_15.png'].map((src, index) => (
                      <li key={index} data-parallax='{"x": 100, "y": 100}'>
                        <img src={`https://demo.wepos.ai/wp-content/plugins/saasland-core/widgets/images/appart-new/${src}`} alt="What We Offer" className="br_shape" decoding="async" />
                      </li>
                    ))}
                  </ul> */}
                  <div className="container d-flex flex-column my-5">
                    <div className="title-four h_color text-center">
                      <h2 className="wow fadeInUp faq-title"> What We Offer </h2>
                      <p className="p_color wow fadeInUp" data-wow-delay="200ms">
                        At the core of WePOS is a suite of meticulously crafted tools designed to elevate every facet of your operations:
                      </p>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-6 d_flex">
                      <Accordion flush open={open} toggle={toggle}>
                        {faqItems.map(({ id, title, icon, body }) => (
                          <AccordionItem eventKey={id} key={id} className={`wow fadeInUp ${activeKey ? 'bg-expanded' : ''}`}>
                            <AccordionHeader targetId={id}>
                              <FontAwesomeIcon icon={icon} className="faq-icons"/>
                              {title}
                            </AccordionHeader>
                            <AccordionBody accordionId={id}>
                              <p>{body}</p>
                            </AccordionBody>
                          </AccordionItem>
                        ))}
                      </Accordion>
                      </div>
                      <div className="col-md-6">
                        <div className="faq_image_mockup wow fadeInUp" data-wow-delay="200ms">
                          <img src={mobile_mockup_2} alt="featured" decoding="async" />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div id="features" className="elementor-widget">
              <div className="elementor-widget-container">
                <section className="best_screen_features_area features_area_pad">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="features_area_pad_svg">
                    <path fill='#ffffff' d="M444.936,252.606c-148.312,0-305.161-29.63-444.936-80.214V0H1920V12S808.194,234.074,444.936,252.606Z" />
                  </svg> */}
                  {/* <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    {['line_07.png', 'line_08.png', 'line_09.png', 'line_11.png', 'line_12.png', 'line_13.png', 'line_14.png', 'line_15.png', 'line_16.png', 'line_10.png'].map((src, index) => (
                      <li key={index} data-parallax='{"x": 100, "y": 100}'>
                        <img src={`https://demo.wepos.ai/wp-content/plugins/saasland-core/widgets/images/appart-new/${src}`} alt="Some of the Best Features" className="br_shape" decoding="async" />
                      </li>
                    ))}
                  </ul> */}
                  <div className="container d-flex flex-column">
                    <div className="title-four h_color text-center mb_0 my-5">
                      <h2 className="wow fadeInUp faq-title"> Some of the Best Features </h2>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-lg-4">
                        <div className="b_screen_img wow fadeInUp">
                          <img src={mobile_mockup1} alt="featured" decoding="async" />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="row b_features_info">
                          {[
                            { icon: faChartColumn, title: 'Bulk Messaging', description: 'Design campaigns for optimal engagement. Manage and schedule campaigns effectively.' },
                            { icon: faPenToSquare, title: 'Campaign Management', description: 'Broadcast messages to multiple contacts through WhatsApp.' },
                            { icon: faKey, title: 'Compliance and Personalisation', description: 'Use pre-approved message templates. Customize messages for individual contact needs.' },
                            { icon: faDisplay, title: 'Global Reach', description: 'Support for hundreds of languages and dialects. Expand market presence in fast-growing economies.' },
                            { icon: faChartPie, title: 'Feedback Management', description: 'Centralize customer feedback. Reach out to dissatisfied customers.' },
                          ].map(({ icon, title, description }, index) => (
                            <div key={index} className="col-sm-6">
                              <div className="b_features_item wow fadeInUp">
                                <div className="b_features_icon">
                                  <div className="icon" style={{width: "100%", height: "100%"}}>
                                    <FontAwesomeIcon icon={icon } />
                                    <span className="hover_color"></span>
                                  </div>
                                </div>
                                <h3>{title}</h3>
                                <p>{description}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="elementor-section elementor-top-section elementor-section-full_width elementor-section-height-default">
      <div className="elementor-container elementor-column-gap-no">
        <div className="elementor-column elementor-col-100 elementor-top-column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="clients elementor-widget">
              <div className="elementor-widget-container">
                <section className="protype_clients_logo logo-center">
                  <div className="container">
                    <div className="partner_logo">
                      {logos.map((logo, index) => (
                        <div
                          key={index}
                          className="p_logo_item wow fadeInLeft"
                          data-wow-delay={logo.delay}
                        >
                          <a href="#">
                            <img
                              fetchpriority="high"
                              decoding="async"
                              width="804"
                              height="366"
                              src={logo.src}
                              alt=""
                              sizes="(max-width: 804px) 100vw, 804px"
                              className='client-img'
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
            className="elementor-section elementor-top-section elementor-section-boxed"
            id="pricing"
        >
            <div className="container elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-invisible" style={{width: "100%"}}>
                    <div className="elementor-widget-wrap elementor-element-populated">

                        {/* Heading */}
                        <div className="elementor-widget elementor-widget-heading text-center">
                            <h2 className="elementor-heading-title">FIXED CHARGE + META FEES</h2>
                        </div>

                        {/* Inner Section for Pricing Details */}
                        <section className="elementor-section elementor-inner-section elementor-section-boxed">
                            <div className="elementor-container elementor-column-gap-default1">

                                {/* Image */}
                                <div className="elementor-column elementor-col-25 elementor-inner-column wepos-pricing">
                                    <div className="elementor-widget elementor-widget-image">
                                        <img
                                            src={adminajax}
                                            alt=""
                                            width="309"
                                            height="80"
                                        />
                                    </div>
                                </div>

                                {/* Price */}
                                <div className="elementor-column elementor-col-25 elementor-inner-column">
                                    <div className="elementor-widget elementor-widget-heading">
                                        <h1 className="elementor-heading-title">£99</h1>
                                        <p className="elementor-heading-subtitle">/per month</p>
                                    </div>
                                </div>

                                {/* Meta Conversation Fees */}
                                <div className="elementor-column elementor-col-25 elementor-inner-column conversation-div">
                                    <div className="elementor-widget elementor-widget-heading">
                                        <h4 className="elementor-heading-title1">
                                            <a href="https://developers.facebook.com/docs/whatsapp/pricing">
                                                + (Conversation Fees)
                                            </a>
                                        </h4>
                                    </div>
                                    <ul className="elementor-icon-list-items">
                                        {["Marketing", "Utility", "Authentication", "Service"].map((item, index) => (
                                            <li key={index} className="elementor-icon-list-item">
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Meta Logo */}
                                <div className="elementor-column elementor-col-25 elementor-inner-column">
                                    <div className="elementor-widget elementor-widget-image">
                                        <img
                                            src={Meta_Logo}
                                            alt=""
                                            width="1024"
                                            height="576"

                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Information Section */}
                        <div className="elementor-widget elementor-widget-heading text-center">
                            <h6 className="elementor-heading-title6">
                                No extra charges. Meta charges are only transferred to you according to the official Meta List Price Schedule.
                            </h6>
                        </div>

                        {/* Icon List */}
                        <ul className="elementor-icon-list-items widget-list text-center">
                            {[
                                "Green Tick Verification & Assistance",
                                "Bulk Messaging and Scheduling",
                                "Customer Tagging",
                                "E-mail support",
                                "Feedback Management",
                                "Reservation Management",
                                "QR Code/URL/NFC",
                                "Data Analytics",
                                "Data Import/Export",
                            ].map((text, index) => (
                                <li key={index} className="elementor-icon-list-item">
                                    <span className="elementor-icon-list-text">{text}</span>
                                </li>
                            ))}
                        </ul>

                        {/* Contact Button */}
                        <div className="elementor-widget elementor-widget-button text-center">
                            <a className="elementor-button elementor-button-link" href="#contact-us">
                                <span className="elementor-button-text">CONTACT SALES</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
      className="elementor-section elementor-top-section elementor-section-full_width contact-us"
      id="contact-us"
      style={{ background: 'classic' }}
    >
      <div className="elementor-background-overlay"></div>
      <div className="elementor-container elementor-column-gap-no container-contact">
        {/* Column 1: Contact Information */}
        <div className="elementor-column elementor-col-33 elementor-top-column contact-detail">
          <div className="elementor-widget-wrap elementor-element-populated">
            {/* Head Office */}
            <div className="elementor-widget elementor-widget-heading">
              <h6 className="elementor-heading-title elementor-size-default">Head Office</h6>
            </div>
            <div className="elementor-widget elementor-widget-text-editor">
              <div className="elementor-widget-container">
                <p>3 Merchant Square,</p>
                <p>Paddington W2 1BF,</p>
                <p>London, United Kingdom</p>
              </div>
            </div>
            {/* Contact Info */}
            <div className="elementor-widget elementor-widget-heading">
              <h6 className="elementor-heading-title elementor-size-default">Contact Info</h6>
            </div>
            <div className="elementor-widget elementor-widget-text-editor">
                <a href="mailto:info@wepos.ai">info@wepos.ai</a>
                <a href="tel:3024437488" style={{ color: '#677294' }}>
                  (+44) 77781 77719
                </a>
            </div>
            {/* Social Icons */}
            {/* <div className="elementor-widget elementor-widget-social-icons">
              <div className="elementor-social-icons-wrapper elementor-grid">
                {['facebook-f', 'twitter', 'vimeo-v', 'pinterest-p'].map((icon, index) => (
                  <span key={index} className="elementor-grid-item">
                    <a className={`elementor-icon elementor-social-icon elementor-social-icon-${icon}`} target="_blank" rel="noopener noreferrer">
                      <span className="elementor-screen-only">{icon.replace(/-/g, ' ')}</span>
                      <i className={`fab fa-${icon}`}></i>
                    </a>
                  </span>
                ))}
              </div>
            </div> */}
          </div>
        </div>

        {/* Column 2: Google Maps */}
        <div className="elementor-column elementor-col-33 elementor-top-column contact-map">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-widget elementor-widget-google_maps">
              <div className="elementor-widget-container">
                <div className="elementor-custom-embed">
                  <iframe
                    loading="lazy"
                    src="https://maps.google.com/maps?q=3%20Merchant%20Square%2C%20Paddington%2C%20London%2C%20United%20Kingdom&t=m&z=14&output=embed&iwloc=near"
                    title="3 Merchant Square, Paddington, London, United Kingdom"
                    aria-label="3 Merchant Square, Paddington, London, United Kingdom"
                    style={{ width: '100%', height: '100%' }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Column 3: Placeholder or additional content */}
        {/* <div className="elementor-column elementor-col-33 elementor-top-column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-widget elementor-widget-Saasland_heading">
              <div className="elementor-widget-container">
                <div className="heading_wrap d-flex justify-content-start">
                  <h2 className="saasland_heading"></h2>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-78ef1e2c elementor-section-boxed elementor-section-height-default elementor-section-height-default">
        <div className="elementor-container elementor-column-gap-no container">
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-66723f4a" style={{width: '100%', marginTop: '45px'}}>
                <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-widget elementor-widget-heading">
                        <div className="elementor-widget-container">
                            <h1 className="elementor-heading-title elementor-size-default want-tojoin-title">Want to join WePOS?</h1>
                        </div>
                    </div>
                    <div className="elementor-widget elementor-widget-text-editor">
                        <div className="elementor-widget-container">
                            <p className='elementor-heading-title6' style={{textAlign: 'start'}}>Get a free demo to find out your best use cases and how WePOS works. We just need a few details:</p>
                        </div>
                    </div>
                    <form className="contact_form_box" method="post" aria-label="Contact form">
                        <div className="row gx-3 gy-3">
                            {["Your Name", "Your Email", "Company Name", "Mobile Number"].map((placeholder, index) => (
                                <div key={index} className="col-lg-6">
                                    <div className="form-group text_box">
                                        <input
                                            type={placeholder.includes("Email") ? "email" : placeholder.includes("Mobile") ? "tel" : "text"}
                                            className="wpcf7-form-control wpcf7-text"
                                            placeholder={placeholder}
                                            required={placeholder !== "Your Name"}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className='col-lg-6 d-flex'>
                              <input class="wpcf7-form-control wpcf7-submit has-spinner btn_three" type="submit" value="Submit"></input>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
     <section
      className="elementor-section elementor-top-section elementor-element elementor-element-540ce08c elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      id="testimonial"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5263c1d3"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-f506d83 elementor-absolute elementor-widget elementor-widget-heading">
              <div className="elementor-widget-container text-center">
                <h1 className="elementor-heading-title elementor-size-default faq-title my-5">
                  Real Results. Real Stories!
                </h1>
              </div>
            </div>
            <div className="elementor-element elementor-element-269916cd elementor-widget elementor-widget-saasland_testimonial">
              <div className="elementor-widget-container">
                <section className="app_testimonial_area">
                  <div className="text_shadow" data-line="Feedback"></div>
                  <div className="container nav_container">
                    <div className="shap one"></div>
                    <div className="shap two"></div>
                    <Carousel>
                      <Carousel.Item className='p-4'>
                      <div className="app_testimonial_item text-center">
                        <div className="author-img">
                          <img
                            loading="lazy"
                            decoding="async"
                            width="100"
                            height="100"
                            src={IMG_4273}
                            alt="Pushker Mitroo"
                            style={{borderRadius:'100%'}}
                          />
                        </div>
                        <div className="author_info">
                          <h6 className="f_p f_500 f_size_18 t_color3 mb-0">
                            Pushker Mitroo
                          </h6>
                          <p>Bonzai London - General Manager</p>
                        </div>
                        <p className="f_300 elementor-heading-title6">
                          WePOS has been a game-changer for Bonzai London. Its
                          targeted marketing tools have allowed us to connect
                          personally with every guest, leading to a significant
                          increase in repeat visits and revenue. Running multiple
                          campaigns with ease, WePOS has not only improved our
                          operational efficiency but has also enhanced our guest
                          engagement. Highly recommended for any restaurant aiming
                          to boost their bottom line.
                        </p>
                      </div>
                      </Carousel.Item>
                      <Carousel.Item className='p-4'>
                        <div className="app_testimonial_item text-center">
                        <div className="author-img">
                          <img
                            loading="lazy"
                            decoding="async"
                            width="100"
                            height="100"
                            src={IMG_4274}
                            alt="Deepak Yadav"
                            style={{borderRadius:'100%'}}
                          />
                        </div>
                        <div className="author_info">
                          <h6 className="f_p f_500 f_size_18 t_color3 mb-0">
                            Deepak Yadav
                          </h6>
                          <p>Bollywood Nights London - Founder</p>
                        </div>
                        <p className="f_300 elementor-heading-title6">
                        After 10+ years of managing customer data merely through my phone contacts, WePOS has transformed how I utilize this information. Now, I can not only store but also effectively use this data for targeted marketing of my events. Especially for last-minute promotions or filling up remaining spots, WePOS’s integration with WhatsApp has been invaluable. It's a game-changer for any event organizer.
                        </p>
                      </div>
                      </Carousel.Item>
                      <Carousel.Item className='p-4'>
                      <div className="app_testimonial_item text-center">
                        <div className="author-img">
                          <img
                            loading="lazy"
                            decoding="async"
                            width="100"
                            height="100"
                            src={arth100}
                            alt="Vinayak Girgune"
                            style={{borderRadius:'100%'}}
                          />
                        </div>
                        <div className="author_info">
                          <h6 className="f_p f_500 f_size_18 t_color3 mb-0">
                            Vinayak Girgune
                          </h6>
                          <p>the arth - Director</p>
                        </div>
                        <p className="f_300 elementor-heading-title6">
                        WePOS has been pivotal for The Arth as a new Indian fine dining spot in West London. It simplifies data collection via QR codes and enhances our marketing directly through WhatsApp, allowing us to send targeted offers and track reservations effectively. This tool has not only increased our repeat customers but also supports our unique 'Connect Meet-ups' for networking. Highly recommend for any restaurant looking to grow their customer base.
                        </p>
                      </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
    className="elementor-section elementor-top-section elementor-element elementor-element-73f11047 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
    data-id="73f11047"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-no">
      <div
        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4ee10775"
        data-id="4ee10775"
        data-element_type="column"
      >
      <div className="faq-container">
            <h2 className="faq-title">Frequently Asked Questions</h2>
            <div className="faq-content mt-5">
              <div className="quick-navigation">
                <h3 className="quick-navigation-title">Quick Navigation</h3>
                <ul>
                  <li className="quick-link active">License</li>
                  <li className="quick-link">WePOS</li>
                  <li className="quick-link">WhatsApp API</li>
                </ul>
              </div>
              <div className="faq-details">
                <h3 className="quick-navigation-title">License</h3>
                <h4 className="faq-question active">Is the service GDPR-compliant?</h4>
                <p className="faq-answer">
                  WhatsApp messages between the WePOS API and customers are end-to-end encrypted. WhatsApp cannot read them. Therefore this solution is more secure than email or many other messengers, that is not encrypted. Our hosting servers (where encryption and decryption happen) are located in certified data centers where our customers need them to be hosted.
                </p>
                <p className="faq-answer">
                What is also to be mentioned for customers using our API: We do not store any message data after messages are retrieved.
                </p>
                <p className="faq-points">
                  The 2 main areas to look at are:
                </p>
                <ol>
                  <li>Data consent (double opt-in, unsubscribe visible...)</li>
                  <li>Data management (ability to share and delete customer information, data stored in the EU...)</li>
                </ol>
                <div className="faq-divider"></div>
                <div className="accordion-container">
                  <h4 
                    className={`faq-question accordion ${isOpen ? 'active' : ''}`} 
                    onClick={toggleAccordion}
                  >
                    Is there any minimum commitment required for WePOS subscriptions?
                  </h4>
                  {isOpen && (
                    <div className="panel">
                      <p>Yes, WePOS requires a minimum commitment of one year. Subscription fees can be paid on a monthly basis.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
    <section className="resources-section">
      <div className="container">
        <div className="row footer-sec">
          {/* Resources Column */}
          <div className="col">
            <h2 className='want-tojoin-title'>Resources</h2>
            <ul className="icon-list">
              <li><a href="#" className='elementor-heading-title6'>FAQ</a></li>
            </ul>
          </div>

          {/* Community Column */}
          <div className="col">
            <h2 className='want-tojoin-title'>Community</h2>
            <ul className="icon-list">
              <li><a href="#" className='elementor-heading-title6'>Community Link</a></li>
            </ul>
          </div>

          {/* Social Column */}
          <div className="col">
            <h2 className='want-tojoin-title'>Get Social</h2>
            <ul className="icon-list-inline">
              <li><a href="#" className='elementor-heading-title6'><FontAwesomeIcon icon={faInstagram} /></a></li>
              <li><a href="#" className='elementor-heading-title6'><FontAwesomeIcon icon={faFacebook} /></a></li>
              <li><a href="https://www.youtube.com/@WePOS-jm5ic" className='elementor-heading-title6'><FontAwesomeIcon icon={faYoutube} /></a></li>
              <li><a href="#" className='elementor-heading-title6'><FontAwesomeIcon icon={faSquareDribbble} /></a></li>
            </ul>
          </div>

          {/* Gallery Column */}
          <div className="col">
            <div className="gallery">
              <figure>
                <a href={Meta1000x750}>
                  <img src={Meta1000x750150} alt="Meta1000x750" />
                </a>
              </figure>
              <figure>
                <a href={ISO_27001_Final_JPG}>
                  <img src={ISO_27001_Final} alt="ISO 27001 Final Logo" />
                </a>
              </figure>
              <figure>
                <a href={PNG_GDPR}>
                  <img src={PNG_GDPRe1} alt="PNG GDPR" />
                </a>
              </figure>
            </div>
          </div>
        </div>

        {/* Footer Section */}
        <div className="footer">
          <div className="footer-left">
            <ul className="icon-list">
              <li>© Copyright 2024 WePOS. All Rights Reserved.</li>
            </ul>
          </div>
          <div className="footer-right">
            <ul className="icon-list-inline">
              <li><a href="https://wepos.ai/site/?page_id=3" className='elementor-heading-title6'>Privacy Policy</a></li>
              <li><a href="https://wepos.ai/site/?page_id=3" className='elementor-heading-title6'>Terms & Conditions</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
</div></>
  );
}

export default Header;
